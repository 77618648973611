body
{
  font-family: 'Poppins', sans-serif;
}
.container {
  max-width: 1490px !important;
}
h1 {
  font-size: 34px;
  font-weight: 600; }

a{
  text-decoration: none !important;
}
#svgimg {
  height: 75px;
}
.c-app,.c-footer
{
  background:#fff;
}
.c-footer
{
  height: auto !important;
}

// Here you can add other styles
.c-sidebar {
    color: #fff;
    background: #3c3c6e;
}
.c-sidebar .c-sidebar-nav-link.c-active, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle
{
    
    background: #0078d2;
    transition: .3s ease;
}
.c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover
{
  background: #b22335;
}
// .c-sidebar .c-sidebar-brand
// {
//     background: #e2e2e2;
// }

.dashboard-wrapper {
  background: #f4f6f9;
}
.dashboard-wrapper .card {
  background-color: #fff;
background-clip: border-box;
border: 0 solid rgba(0,0,0,.125);
}
.c-sidebar {
  color: #fff;
  background: #1d2228;
}
.dashboard-wrapper button.btn-primary {
  background: #0078d2;
  border: 1px solid #0078d2;
  box-shadow: 1px 1px 5px #cecece;
  transition: .3s;
}

.dashboard-wrapper button.btn-primary:hover,.usb-btn:hover{
  -webkit-transform: translateY(-6px);
  transform: translateY(-4px);
  box-shadow: -3px 4px 8px #cecece;
  color: #fff;
}

.inner-page {
  font-size: 14px;
  line-height: 21px;
}
//colors
.usb-btn 
{
    background: #0078d2;
    color: #fff;
    transition: .5s ease;
    font-size: 13px;
    line-height: 21px;
    
    &:hover
    {
        color: #fff;
    }
}

.c-header.c-header-light.c-header-fixed.c-header-with-subheader button.usb-btn:hover {
  transform: none;
}

//USers
.c-app.index-page
{
  background: #fff;
  .heading-text
  {
    font-weight: bold;
    margin-bottom: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 5px;
    margin-top: -13px;
    
  }
  
}
.index-page .c-header .auth-icon
{
  height: 15px;
padding-right: 5px;
}
.index-page .c-header{
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
  top: 0;
}
.home-content-wrapper 
{
  align-items: flex-start;
  padding-bottom: 20px;
  padding-top: 167px;
}
.inner-page .c-header {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
}
.inner-page .list-wrap.card {
  box-shadow: 2px 3px 13px #cecece;height: 95%;
}
.heading-text h1 {
  color: #fff;
  line-height: 35px !important;
  font-size: 22px !important;
  background: rgba(0, 120, 210,0.85);
}
.top-heading.container-fluid .heading-text h1 {
  padding: 0 !important;
}
.index-page .card-header 
{
  color: #3c3c6e;
text-transform: uppercase;
letter-spacing: 1.2px;
}
// .index-page .heading-text
// {
//   margin-bottom: 40px;
//   font-weight: bold;
// }

 
//footer
.index-page .c-footer
{
  background: none;
  height: auto;
  padding-bottom: 0 !important;
}
.c-footer {
  padding-bottom: 0 !important;
}

.bottom-footer {
  margin-top: 30px;
  border-top: 1px solid #d8dbe0;
  display: flex;
  width: 100%;
}
.index-page .c-footer{
  border: none;
}


 




//Inner page
.inner-sidebar 
{
  background: none;
}
.inner-sidebar button {
  display: block !important;
  width: 100%;
  text-align: left !important;
}
.inner-sidebar button {
  display: block !important;
  width: 100%;
  text-align: left !important;
  padding: 15px;
  border-radius: 7px !important;
  margin-bottom: 11px !important;
}

//Auth
.input-heading {
  display: block;
  width: 100%;
}
.input-heading p {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.5px;
}
.inner-page .breadcrumb
{
  border: none;
  padding: 0;
}
.inner-page .inner-page-banner {
  padding-top: 140px;
}
.inner-page .inner-heading {
  padding-bottom: 10px !important;
  margin-top: 0 !important;
}
.pie-chart-wrapper
{
  border: none;
  
}
.pie-chart-wrapper .piechart-nav
{
  position: relative;
}
.pie-chart-wrapper .piechart-nav canvas
{
  height: 90vh !important;
  
}
.pie-chart-wrapper .piechart-nav::before
{
  background-image: url('http://www.theage.com.au/ffximage/2007/09/12/mario300_narrowweb__300x392,0.jpg');
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100px;
  width: 100px;
  transform: translate(-50%,-50%);
  border-radius: 100%;
}
.pie-bg
{
  
  background-repeat: no-repeat;
  background-position: center;
}


.half-width-form .input-group.mb-3 {
  float: left;
  width: 45%;
  clear: left;
}

.half-width-form .input-group.mb-4 {
  float: right;
  width: 45%;
  clear: right;
}

.usb-btn a{
  color: #fff;
  text-decoration: none;
}
.usb-btn:hover {
  background: #3c3c6e;
}


.card-footer 
{
  display: table;
  width:100%;
}

.half-width-form .mb-4
{
  margin-bottom:0 !important;
}
.content-image {
  overflow: hidden;
  max-height: 238px;
}

.listing-icon svg {
  height: 24px !important;
  width: 24px !important;
}

.list-wrap a {
  color: #424E5C !important;
  font-size: 17px;
  line-height: 23px;
  text-decoration: none;
}

.mbb-4{
  margin-bottom: 8px;}

//admin user page
.admin-user-page table td
{
  padding: 12px 20px !important;
}



//inner listing page

.inner-page h1 {
  font-weight: 600;
  font-size: 31px;
  line-height: 39px;
  
}
.faq-page h1 {color: #fff;}
.inner-page .c-header-fixed
{
  top: 0;
}

.inner-page h3 {
    font-size: 27px;
    font-weight: bold;
}


.details-content {
  width: 100%;
  background: #fff;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
  max-width: 1000px;
margin: 0 auto;
    margin-top: 0px;
    margin-bottom: 0px;
margin-top: -80px;
z-index: 999;
}
.inner-thumbnail-wrap {
  max-height: 400px;
  overflow: hidden;
}

.banner-wrapper
{
  position: relative;
  height: 400px;
  background-size: cover;
  width: 100%;
}

.auth-page .card-group
{
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
}
.auth-page .card
{
  border: none;
}
.auth-page
{
  border-left: 1px solid #c0c0c0;
}

.inner-content-wrapper.search-page
{
  padding-top: 186px !important;
}

.custom-control-input:checked ~ .checkbox-radio::before {
  color: #fff;
  border-color: #321fdb;
  background-color: #321fdb;
}


//AdminDashboard
// .dashboard-table table tr {
//   display: table;
//   width: 100%;
// }

.dashboard-table table tr td {
    display: table-cell;
    vertical-align: middle !important;
}
.dashboard-table table tr td button {
  margin-bottom: 8px;
  font-weight: 400;
}


.custom-tooltip + .tooltip > .tooltip-inner {
  background-color: #73AD21; 
  color: #FFFFFF; 
  border: 1px solid green; 
  padding: 15px;
  font-size: 20px;
}

//Responsive


@media (min-width: $md)
{
  .home-nav nav {
    position: relative;
    background: white;
    padding: 50px;
    height: 630px;
    width: 630px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    padding: 0;
    border: 4px solid #fff;
}
.center-logo-box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    color:#ffffff;
    z-index: 10;
    font-family: 'Montserrat-Bold';
    font-size: 18px;
    
}
.center-logo-box p{
    z-index: 1000;
    text-align: center;
    position: relative;
    font-weight: bolder;
}

.center-logo-box:before {
    content: '';
    box-sizing: border-box;
    background: #fff;
    height: 180px;
    width: 180px;
    margin: 0;
    padding: 0;
    display: block;
    position: absolute;
    top: calc(50% - 90px);
    left: calc(50% - 90px);
    border-radius: 50%;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    z-index: 150;
    // background: url('../img/logo.png')
}
.center-logo-box img {
  height: 160px;
  width: 167px;
  z-index: 9999;
  position: absolute;
  top: -95px;
  left: -85px;
  border-radius: 37%;
}

.center-logo-box:after {
    content: '';
    box-sizing: border-box;
    background: #fff;
    height: 200px;
    width: 200px;
    margin: 0;
    padding: 0;
    display: block;
    position: absolute;
    top: calc(50% - 100px);
    left: calc(50% - 100px);
    border-radius: 50%;
    z-index: 100;
}
.nav-item-content{
    background-image: unset;
    text-align:unset;
    margin-top:0;
    padding:0;
  }
  .nav-item-content:hover{
      background-image:unset;
  }
.home-nav .nav-item{
    text-align:center;
    list-style: none;
    background:rgb(0,120,210);
    position:absolute;
    border:3px solid #fff;
    bottom:50%;
    left:50%;
    width:350px;
    height:350px;
    transform-origin: bottom right;
    font-size: 12.5px;
line-height: 14px;
  }
  .hidden-div { display: none;}
  .nav-item-content{
    color:white;
    position:absolute;
  }
  .nav-item-one{
    transform: skew(-55deg) rotate(284deg);
    top: 63%;
    right: 21%;
  }
  .nav-item-two {
    transform: skew(-54deg) rotate(288deg);
    top: 64%;
right: 18%;
  }
  .nav-item-three{
    top: 64%;
    transform: skew(-54deg) rotate(289deg);
    right: 21%;
  }
  .nav-item-four{
    top: 66%;
    transform: skew(-55deg) rotate(294deg);
    right: 17%;
  }
  .nav-item-five{
    top: 65%;
    transform: skew(-55deg) rotate(108deg);
    right: 14%;
  }
  .nav-item-six{
    top: 65%;
    transform: skew(-54deg) rotate(108deg);
    right: 13%;
  }
  .nav-item-seven{
    top: 66%;
transform: skew(-54deg) rotate(112deg);
right: 13%;
  }
  
  .nav-item-eight{
    top: 64%;
    -webkit-transform: skew(-50deg) rotate(110deg);
    transform: skew(-54deg) rotate(110deg);
    right: 15%;
  }
  .nav-item-nine{
    top: 60%;
transform: skew(-54deg) rotate(112deg);
right: 14%;
  }
  .nav-item-ten{
    top: 65%;
    transform: skew(-54deg) rotate(107deg);
    right: 19%;
}
  .nav-item-content .fa{
    font-size:2em;
  }
  .home-nav .nav-item:hover{
    background-image: radial-gradient( circle farthest-corner at 1.2% 5.3%,  rgba(255,85,166,1) 0%, rgba(255,154,98,1) 100.2% );
    box-shadow:0 0 5px 0 rgba(0,0,0,0.2);
    z-index:5;
  }
  .index-page .c-sidebar-nav-icon
  {
    height: 30px;
  }
  .auth-page .register
  {
    padding: 28px 0;
  }
.dropdown-item.active, .dropdown-item:active{
  color: #321fdb;
  background-color: transparent;
}
  





/*bottom*/
.home-nav.nav-bottom {
    top:50%;
    right: initial;
    left:50%;
    transform: translate(-50% , -50%);
    box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.2);
}

.nav-bottom.nav-needle .nav-list:before {
    left: calc(50% - 5px);
    top: 18%;
    height: 120px;
    width: 10px;
    box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.2);
}

.nav-bottom ,
.nav-bottom .nav-needle .nav-item:after {
    box-shadow:0  0px 20px rgba(0, 0, 0, 0.4);
   
}

.nav-bottom .nav-item:nth-child(1) {
    transform: translateX(-100%) rotate(0deg) skew(54deg)
}

.nav-bottom .nav-item:nth-child(2) {
  transform: translateX(-100%) rotate(36deg) skew(54deg);
}

.nav-bottom .nav-item:nth-child(3) {
    transform: translateX(-100%) rotate(72deg) skew(54deg);
}

.nav-bottom .nav-item:nth-child(4) {
    transform: translateX(-100%) rotate(108deg) skew(54deg);
}

.nav-bottom .nav-item:nth-child(5) {
    transform: translateX(-100%) rotate(144deg) skew(54deg);
}

.nav-bottom .nav-item:nth-child(6) {
    transform: translateX(-100%) rotate(180deg) skew(54deg);
}

.nav-bottom .nav-item:nth-child(7) {
    transform: translateX(-100%) rotate(216deg) skew(54deg);
}

.nav-bottom .nav-item:nth-child(8) {
    transform: translateX(-100%) rotate(252deg) skew(54deg);
}

.nav-bottom .nav-item:nth-child(9) {
    transform: translateX(-100%) rotate(288deg) skew(54deg);
}
.nav-bottom .nav-item:nth-child(10) {
  transform: translateX(-100%) rotate(324deg) skew(54deg);
}

/*****homepage****/






}
.index-page .px-0.card-body img {
  height: 189px;
  width: 100%;
}
/************Ipad*******/
@media (min-width: $md) and (max-width: $md2) {
  .usb-btn {font-size: 11px;
    line-height: 12px;
    padding: 8px 11px;}
    .c-footer {
      padding: 0;
      padding-top: 20px;
  }
  .home-nav {
    margin-left: -3%;
}
.c-footer .col-md-3 {
  padding: 0 !important;
}
  .home-nav nav
  {
    height: 380px;
    width: 380px;
  }
  .nav-item-one {top: 80%;
    right: 16%;}
    .nav-item-two { top: 81%; right: 20%;}
    .nav-item-three { top: 81%; right: 20%;}
    .nav-item-four { top: 81%; right: 20%;}
    .nav-item-five { top: 81%; right: 20%;}
    .nav-item-six { top: 81%; right: 20%;}
    .nav-item-seven { top: 81%; right: 20%;}
    .nav-item-eight { top: 81%; right: 20%;}
    .nav-item-nine { top: 81%; right: 20%;}
    .nav-item-ten { top: 81%; right: 20%;}

  .nav-item-content p {
      display: none;
  }


}


@media (min-width: $md2) and (max-width: $md3) {
  .nav-item-one {
   
    top: 67%;
    right: 19%;
}
  .home-nav nav { height: 540px; width: 540px;}
  .nav-item-two {top: 67%;
    right: 14%;}
    .nav-item-three {
      top: 70%;right: 20%;}
      .nav-item-four{ right: 22%;}
      .nav-item-five{top: 67%;right: 21%;}
      .nav-item-six{top: 72%;right: 17%;}
      .nav-item-seven{top: 70%; right: 17%;}
      .nav-item-eight{top: 69%;right: 19%;}
      .nav-item-nine{top: 69%;right: 13%;}
      .nav-item-ten{top: 67%;right: 19%;}

}
.mobile-login-logo { display: none;}
// .heading-text h1 {
//   display: none;
// }
.c-footer.dashboard-footer {
  justify-content: space-between;
}
/*****Mobile*********/
@media (max-width:$min)
{
  
  .top-heading.container-fluid .heading-text h1 {
    padding: 5px 0 !important;
    font-size: 17px !important;
}
  .heading-text h1 {
    color: #fff;
    line-height: 38px !important; }
  .px-4.pt-0.card-body {
    padding: 0 !important;
}
  .inner-details-list {
    max-width: 100% !important; }
  .c-sidebar.c-sidebar-dark.c-sidebar-show.c-sidebar-fixed {
    padding-top: 37px;
}
.inner-details-list h2 {
  display: block !important;
  width: 100% !important;
} 
.dashboard-wrapper .row {
  margin-left: 0px;
}
.details-list { display: block !important;}
  .c-footer.dashboard-footer {
    padding: 20px !important;
}
  .form-group.my-0.align-items-center .input-group-append {
    z-index: 0;
}
.gallery-wrapper { display: block !important;}
.gallery-wrapper__del {
  position: absolute !important; }
  .gallery-wrapper__wrap {
    margin-bottom: 19px;
}
footer .mfs-auto {
  margin: none !important;
}
.mobile-header { height: 150px !important;}
  .c-header-nav.profile-wrap {
      position: absolute;
      top: -117px;
      z-index: 1;
      right: 0;
  }
  .mobile-login-logo { display: block;}
  .banner-wrapper {height: 100%;width: 100%;
    background-size: cover; }
    .inner-page .info-wrap {
      margin-bottom: 35px;
  }
  .inner-page .info-wrap .usb-btn {
    margin-right: 12px !important;
    margin-bottom: 10px !important;
}
.details-content { margin-top: 0;}
    .nav-item-content {
      background: #0078d2;color: #fff;
      text-align: center;
      padding: 15px;
      margin-bottom: 20px;
  }
 
  .center-logo-box img { display: none; position: static;}
  .small_screen_center { text-align: center;}
  .heading-text h1 {
    font-size: 26px;
    line-height: 35px;

  }
  .mr-auto.c-header-nav a {
    text-align: center;
    margin: 0 auto;
  }
  .c-header-with-subheader {
    padding-bottom: 0px;
  }
  // .c-app.index-page {
  //   padding-top: 38px;
  // }
  .mr-auto.c-header-nav {
    display: inline-flex;
  }
  .px-3.c-header-nav {
    float: right;

  }
  .border-left.card {
    width: 100% !important;
  }
  .auth-page .card { margin-bottom: 0 !important;padding-bottom: 0 !important;}

  /*****Homepage***/
  .heading-text h1 {
    color: #fff;
    line-height: 20px !important;
    font-size: 18px !important;
  }
  .home-content-wrapper {
    padding-top: 233px;
  }
  .c-header.c-header-fixed {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .home-content-wrapper {
    padding-top: 36px;
  }
  .nav-item-content.nav-item-five p {
    margin-bottom: 0;
}
.nav-item-content {padding: 10px 10px 5px;}
.nav-item-content br { display: none;}
.c-footer .col-md-3 {
  padding: 0;
}
.c-footer { padding: 0;}
.usb-btn {}
.c-footer .usb-btn {
  display: block;
  width: 100%;
  text-align: left;
  padding: 10px 16px;
  margin-bottom: 8px !important;
}
.bottom-footer {
  display: block;
  width: 100%;
  text-align: center;
}
.bottom-footer .mfs-auto.text-right {
  margin-left: none !important;
  display: block;
  text-align: center !important;
}
.c-footer .col-md-6 {
  padding: 0;
}
.c-footer .advertise-section.card {padding-top: 12px;}
.bottom-footer {
  margin-top: 0px; }


  /******inner page****/
  .inner-page .inner-page-banner {
    padding-top: 0;
}
.inner-heading.py-5 {
  padding-top: 24px !important;padding-bottom: 0 !important; }
  .inner-page .breadcrumb {
    margin: 0;
}
.inner-page .inner-page-banner {
  padding-top: 0;
  padding-bottom: 20px;
}
.usb-btn {
  margin: 0 !important; }
  .advertise-section.card,.advertise-section.card button {
    text-align: center;
}
.advertise-section.card button { margin-top: 8px !important;}
.inner-page h1 {
  font-size: 27px;
  margin-bottom: 0;
  padding-bottom: 0 !important;
}
.half-width-form .input-group.mb-3,.half-width-form .input-group.mb-4 {
  float: none;
  width: 100%;
  clear: both;
}
.add-form-wrapper.card .card-body {
  padding: 8px 9px;
}
.address-group input {
  margin-bottom: 9px;
}
.add-form-wrapper button {
  margin-right: 10px !important;
}
.add-form-wrapper .card-body .col-md-12 {
  padding: 8px 0;
}
.news-page .table td, .inner-page-table .table td {
  word-break: unset !important;}
  .heading-text h1 {
    color: #fff;
    line-height: 23px !important;
    font-size: 22px !important;
    background: rgba(0, 120, 210,0.85);
  }
  .inner-page .table .usb-btn {
    width: 128px !important;
    max-width: 100%;
    display: inline-block;
    text-align: center;
}

.inner-page .detail-gallery .gallery-wrapper__wrap img {
  height: auto;
  text-align: center;
  width: 100%;
}
.inner-page .detail-gallery .gallery-wrapper__wrap {
width: 100%;
text-align: center;
}
}


/****** Change password page***/

.c-app.c-default-layout.flex-row.auth-page.change-password {
  border-left: none;
  border: 1px solid #cecece;
}
.auth-page.change-password .card-group {
  box-shadow: none;
 
}
.auth-page.change-password h3 {
  font-size: 24px;
  font-weight: 400;
}
.password-guides {
  max-width: 500px;
  border: 1px solid #cecece;
  padding: 43px 18px 41px 21px;
  margin-top: 45px;
}
.cicon-wrap {
  text-align: center;
  margin: 20px 0;
}
.password-guides li {
  margin-bottom: 10px;
}
.password-guides h3 {
  font-size: 22px !important;
}
.password-guides ul {
  padding-left: 18px;
}


/******My profile****/
// .my-profile .card-header {
//     margin-bottom: 40px;
//   }
  .user-profile-pic {
    position: relative;
    max-width: 219px;
    margin: 0 auto; 
    text-align: center;
    margin-bottom: 25px;
    transform: translateX(80px);
}
 
.user-profile-pic img {
  height: 208px !important;
  border-radius: 100%;
  box-shadow: 1px 2px 9px #cecece;
  width: 208px;
}
.user-profile-pic a {
  position: absolute;
bottom: 14px;
right: 19px;
background: #39f;
border-radius: 100px;height: 40px;
width: 40px;
padding: 6px 4px; }
.user-profile-pic a .c-icon {
  height: 22px !important;
  width: 22px !important;
  color: #fff;
}

.profile-dialog .modal-dialog {
  max-width: 415px;
  text-align: center;
}

.profile-dialog .modal-dialog img {
  height: 197px !important;
}

.profile-dialog #profile {
  padding-top: 24px;
  padding-left: 62px;
}

/**home page***/
.home-page-header .c-header-nav {justify-content: center;}
.homepage-logo a {
  text-align: center;
  margin: 0 auto;
}

.full-width { width: 100% !important;}


/*****Login Register****/
.input-heading .text-danger {
  font-size: 16px;
  line-height: 13px;
}
.input-heading .text-secondary {
  color: #d15ba2 !important;
  font-weight: 400;
}

table tr td img {
  box-shadow: 1px 1px 8px #cecece;
}

/***FAqs**/
.faq-section {}
.static-page-banner .breadcrumb {justify-content: center;}
.static-page-banner .breadcrumb-item a, .static-page-banner .breadcrumb-item {
  color: #fff !important;
}
.inner-page .static-page-banner{
  padding-top: 70px;
  border-bottom: 1px solid #cecece;
  margin-bottom: 53px;
  margin-top: 146px;
  padding-bottom: 47px;
}
.faq-section h5 {
  color: #3c3c6e;
  text-transform: capitalize;
line-height: 35px;
}
.faq-section h5:hover,.faq-section .btn-link:hover,.faq-section .btn-link{ text-decoration: none;
outline: none; box-shadow: none;}


/*** About***/
.about-section iframe {
  height: 376px;
  width: 100%;
  overflow-y: hidden;
  border: none;
}
.about-section h1 {
  color: #3c3c6e;
}
.about-section, .faq-section {
  font-size: 14px;
  color: #000;
  line-height: 23px;
  font-weight: 300;
}
.footer-heaading {
  margin-bottom: 8px;
}
.inner-content-wrapper .add-form-wrapper {
  box-shadow: -1px 2px 20px rgba(0, 0, 0, 0.12);
}
.c-footer {
  padding-top: 51px !important;
  margin-top: 33px;
}
.faq-page, .faq-page footer {
  background: #fff;
}
.index-page .c-footer {
  margin-top: 0 !important;
}
.add-form-wrapper.card header {
  font-weight: 600;
  font-size: 17px;
  line-height: 25px;
}
.add-form-wrapper .usb-btn ,.add-form-wrapper button[type="reset"]{
  width: 126px;
  padding: 10px 0;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.add-form-wrapper .usb-btn:hover 
{ transform: translateY(0px);}
.add-form-wrapper
{
    position: relative;
}
.add-form-wrapper:before, .add-form-wrapper:after
{
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 15px;
    left: 10px;
    width: 50%;
    top: 80%;
    max-width:300px;
    background: #777;
    box-shadow: 0 15px 10px #777;
    transform: rotate(-3deg);
}
.add-form-wrapper:after
{
    transform: rotate(3deg);
    right: 10px;
    left: auto;
}

.faq-page .card .card-header {
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
}


/****TErms & conditions***/
.terms-and-condition ul li, .terms-and-condition h3, .terms-and-condition h4 {
    margin-bottom: 17px;
  }



  .c-footer .carousel-item img { max-height: 250px;}
  .c-footer .card-body {
    padding: 10px 0;
}
.input-group.mbb-4.address-group select {
  height: 34px;
  padding: 0 5px;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #cecece;
  color: #768192;
  width: 100%;
}

/*****Contact us*****/
.contact-us-wrap {
  max-width: 900px;
  margin: 0 auto;
  padding: 42px 40px;
  border: 1px solid #cecece;
  border-radius: 10px;
  box-shadow: 0px 0px 4px #cecece;
}
.contact-us-wrap h3 {
  margin-bottom: 40px !important;
}
.contact-iframe iframe {
  min-height: 352px;
}
.contact-us-wrap h3, .contact-us-wrap h4 {
  margin-bottom: 10px !important;
  color: #3c3c6e;
}



.contact-us-wrap .form-wrap p{color: #768192; }
.contact-us-wrap .form-wrap button {
  border: none;
}
.contact-us-wrap .form-wrap h4 {
  margin-bottom: 6px !important;
}

.inner-page .input-group-prepend {
  display: none;
}
.video-wrap.card .card-body {
  padding: 0; }

  .py-3.align-items-baseline.inner-sidebar .col-md-12 {
    padding: 0 !important;
}

/*****Dashboard inner page****/
.details-list  { padding-top: 20px;padding-left: 0; display: flex; flex-wrap: wrap;}
.details-list li {
    list-style: none;
    margin-bottom: 20px; }
    .details-list li {
      flex: 0 0 50%;
      padding-right: 30px;
  }
  .inner-details-list {
    max-width: 88%;
    padding-left: 14px;
    padding-bottom: 38px;
    padding: 30px 20px 39px;
box-shadow: 0px -1px 11px #cecece;
margin-bottom: 45px;
background: #fff;
}
.inner-details-list h2 {
  display: inline-block;
  vertical-align: middle;
  width: 50%;
}
.c-footer {
  padding-top: 0 !important; }
.inner-details-list h4 {
    display: block;
    width: 100%;
}
.footer-right a { color: #3c4b64;}
.imgloader{
  max-width: 100%;
  height: auto;
  margin: auto;
  display: table;
}
.ft-12{
  font-size: 12px;
}
.btn-pill {
  border-radius: 7px;
}

/***Job opening****/
.job-section {
  padding: 0 0 18px;
}
.job-section h3 { margin-bottom: 15px;}


/*****Checkout page****/
.checkout-page ul {
  display: flex;
  flex-wrap: wrap; padding-left: 0;}
.checkout-page ul li {
    list-style: none;
    margin-bottom: 9px;}
    .checkout-page ul li {
      flex-basis: 50%;
  }
  .checkout-page img {
    padding-bottom: 30px;
}
.paypal-btn {
  max-width: 50%; }
.c-avatar-img{border: 1px solid;height: 100%;}

  /*****payment success page****/
  .payment-success-page {
    max-width: 767px;
    text-align: center;
    background: #fff;
    padding: 80px 35px 99px;
    margin-bottom: 30px;
}
.payment-success-page i {
  font-size: 82px;
  line-height: 88px;
  color: #0078d2;
  margin-bottom: 19px;
}
.payment-success-page h2 {
  font-size: 19px;
  line-height: 26px;
  font-weight: 300;
}
.payment-success-page h2 span {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: -14px !important;
  display: block;
}

/******Edit user****/
.edit-icon svg {
  height: 44px !important;
  width: 41px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  content: '';
  transform: translate(-50%,-50%);
  background: #0078d2;
  border-radius: 100%;
  padding: 11px;
  color: #fff;
}

.gallery-wrapper__wrap img {
    max-width: 100%;
    height: auto;
}

.gallery-wrapper{
    display: flex;
    flex-wrap: wrap;
}

.gallery-wrapper>* {
    flex: 0 0 24%;
    margin: 0 5px 5px 0;
}
.gallery-wrapper__wrap {
  padding-right: 11px; position: relative;}

  .gallery-wrapper__del {
    position: relative;
    top: 50%;
    left: 50%;
    font-size: 2rem;
    color: #0078d2;
    transform: translate(-50%);
}
.phone-icon {
  transform: rotate(112deg); }


  .inner-content-wrapper .content-image img {
    width: 100%;
    min-height: 252px;
}
.inner-thumbnail-wrap img {
  max-width: 100%; }



.inner-page-table .table td ,.news-page .table td{ vertical-align: middle;}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0078d2;
  border-color: #0078d2;
}
.inner-page-table .table td ,.news-page .table td{word-break: break-all;padding: 10px 6px !important;}

.detail-gallery {
  display: flex;
  flex-wrap: wrap;
}
.detail-gallery .gallery-wrapper__wrap {
  width: 20%;
  margin-bottom: 20px;
}
.detail-gallery .gallery-wrapper__wrap img {
  height: 193px;
}
.listtype-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 50px;
    margin-bottom: 5px;
}

#preloader{
  background:linear-gradient(to right, #409ADD, #9198e5);
  display:flex;
  justify-content:center;
  align-items:center;
  width:100%;
  min-height:100vh;
}
.preloarder{
  position:relative;
  width:100px;
  height:70px;
  display:inline-block;
  transform-origin:center;
  animation:preloarder 1.5s linear infinite;
}
.preloarder .SpanLoad{
  display:flex;
  justify-content:center;
}
.preloarder span{
  position:absolute;
  width:15px;
  height:15px;
  background:#fff;
  transform-origin:center 35px;
  border-radius:50%;
}
.preloarder span:first-child{
  opacity:.1;
}
.preloarder span:nth-child(2){
  transform:rotate(36deg);
  opacity:.2;
}
.preloarder span:nth-child(3){
  transform:rotate(72deg);
  opacity:.3;
}
.preloarder span:nth-child(4){
  transform:rotate(108deg);
  opacity:.4;
}
.preloarder span:nth-child(5){
  transform:rotate(144deg);
  opacity:.5;
}
.preloarder span:nth-child(6){
  transform:rotate(180deg);
  opacity:.6;
}
.preloarder span:nth-child(7){
  transform:rotate(216deg);
  opacity:.7;
}
.preloarder span:nth-child(8){
  transform:rotate(252deg);
  opacity:.8;
}
.preloarder span:nth-child(9){
  transform:rotate(288deg);
  opacity:.9;
}
.preloarder span:nth-child(10){
  transform:rotate(324deg);
  opacity:1;
}

@keyframes preloarder{
  0%{
    transform:rotate(0deg) scale(1);  
  }
  100%{
    transform:rotate(360deg) scale(1);  
  }
}

@media (min-width: 1120px) and (max-width: 1300px) {
  #svgimg {
    height: 63px;
  }
  .nav-item-four {
    right: 17%;top: 70%;
}
.nav-item-six {
  top: 69%;
  right: 12%;
}
.nav-item-five {
  top: 67%;
  right: 13%;
}
}
@media (min-width: 768px) and (max-width: 1120px) {
#svgimg {
  height: 67px;
  padding: 12px 6px;
}
}
header .homepage-logo img {
  height: 108px;
  padding: 6px 0 11px;
}

.home-content-wrapper .carousel-item img {
  height: 220px;
}


@media (min-width:1120px) and (max-width:1920px)
{
    .container {
      width: 100% !important;
      padding-right: 15px !important;
      padding-left: 15px !important;
      margin-right: auto !important;
      margin-left: auto !important;
  }
}

.class-ddown {
  display: none;
}
.targeting-country.row input[type="checkbox"] {
  height: 35px;
  vertical-align: middle;
  width: 18px;
}
.password-wrap {
  position: relative;
}
.password-wrap i {
  position: absolute;
  z-index: 999;
  top: 46%;
  right: 0;
  cursor: pointer;
}

.c-switch-slider{
  background-color: #d8dbe0;
}

.events-inner .c-header.c-header-fixed { z-index: 1;}
.events-inner .details-content {z-index: 1;}
// .events-inner .fslightbox-absoluted {
//   top: 10%;
// }

// .events-inner .fslightbox-nav { top: 25%;}